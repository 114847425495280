import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import MainWrapper from "./Components/MainWrapper";
import { GlobalContextProvider } from "./Context/GlobalContext";
import { getStorage, setStorage } from "./Utils/Storage";

function AppRoutes() {
  useEffect(() => {
    const lang = getStorage("language");

    if (!lang) {
      setStorage("language", { lang: "BR" });
    }
  }, []);

  return (
    <div className="app">
      <GlobalContextProvider>
        <Routes>
          <Route path="/" element={<MainWrapper />}></Route>
        </Routes>
      </GlobalContextProvider>
    </div>
  );
}

export default AppRoutes;
