import "./style.css";
import arrowDown from "../../Assets/arrow-down.svg";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";

function BottomLink({ text, linkTo = "#" }) {
  const [paused, setPaused] = useState(false);

  function handleClick() {
    setPaused(!paused);
  }
  return (
    <div className="bottom-link" onClick={() => handleClick()}>
      <HashLink smooth to={linkTo}>
        <div className="bottom-link-wrapper">
          <p>{text}</p>
          <img className={paused ? "" : "bounce"} src={arrowDown} alt="" />
        </div>
      </HashLink>
    </div>
  );
}

export default BottomLink;
