import "./style.css";
import useGlobal from "../../Hooks/useGlobal";
import useIcons from "../../Hooks/useIcons";
import BottomLink from "../../Components/BottomLink";
import Photo from "../../Assets/my-photo.png";

function Header() {
  const {
    language: { header: content },
  } = useGlobal();

  const icons = useIcons();

  delete icons.figma;
  delete icons.photoshop;
  delete icons.english_2;
  delete icons.linkedin;
  delete icons.whatsapp;
  delete icons.email;

  return (
    <header>
      <div className="hero-container">
        <img className="hero-img" src={Photo} alt="" />

        <h1>{content && content.h1}</h1>
        <p className="sub-text">
          {content.p.strA}
          <span className="yellow">{content.p.contrastA}</span>
          {content.p.strB}
          <span className="yellow">{content.p.contrastB}</span>
          {content.p.strC}
        </p>
        <div className="stack-icons">
          {icons &&
            Object.values(icons).map((value) => (
              <div key={value.name} className="icon-box">
                <div className="img stack-icon">
                  <img src={value.path} alt={`icone ${value.name}`} />
                </div>
                <p>{value.name}</p>
              </div>
            ))}
        </div>
      </div>
      <BottomLink text={content.link} linkTo="#about-me" />
    </header>
  );
}

export default Header;
