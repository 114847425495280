import "./style.css";
import pt_img from "../../Assets/portuguese.svg";
import en_img from "../../Assets/english.svg";
import useGlobal from "../../Hooks/useGlobal";
import { useEffect, useState } from "react";
import { getStorage } from "../../Utils/Storage";

function TopBar() {
  const { changeLang, storedLang } = useGlobal();
  const [activeLang, setActiveLang] = useState(getStorage());
  console.log("Top Bar");
  console.log(storedLang);
  useEffect(() => {
    console.log("activeLang");
    console.log(activeLang);
    if (storedLang) {
      setActiveLang(storedLang.lang);
    }
  }, [storedLang]);

  return (
    <nav>
      <div
        className={`lang ${activeLang === "EN" ? "selected" : "deselected"}`}
        onClick={() => changeLang("EN")}
      >
        <img className="flag" src={en_img} alt="" />
        <p>Ingles</p>
      </div>
      <div
        className={`lang ${activeLang === "BR" ? "selected" : "deselected"}`}
        onClick={() => changeLang("BR")}
      >
        <img src={pt_img} alt="" />
        <p>Portugues</p>
      </div>
    </nav>
  );
}

export default TopBar;
