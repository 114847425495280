import "./style.css";
import useGlobal from "../../Hooks/useGlobal";

function Resume({ openModal, setModalType }) {
  const {
    language: { resume: content },
  } = useGlobal();
  return (
    <section className="resume-container" id="resume">
      <h1>{content.h1}</h1>
      <p className="paragraph">{content.p}</p>
      <button
        onClick={() => {
          openModal(true);
          setModalType("language");
        }}
      >
        {content.btn}
      </button>
    </section>
  );
}

export default Resume;
