import "./video.css";
import video from "../../Assets/payment-management-demo.mp4";

function Video({ content }) {
  const url = {
    pt: "https://drive.google.com/file/d/1XfFZ-JPPqBJ2IPZKfM_GqH17eF9KUnEo/view?usp=sharing",
    en: "https://drive.google.com/file/d/1XfFZ-JPPqBJ2IPZKfM_GqH17eF9KUnEo/view?usp=sharing",
  };

  return (
    <>
      <video autoPlay={"autoplay"} preLoad="auto" muted controls loop>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
}

export default Video;
