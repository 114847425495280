import "./style.css";
import SKillItem from "../../Components/SKillItem";
import useGlobal from "../../Hooks/useGlobal";
import BottomLink from "../../Components/BottomLink";

function Skills() {
  const {
    language: { skills: content },
  } = useGlobal();

  return (
    <section className="skills-container" id="skills">
      <h1>{content.h1}</h1>
      <p className="section-description">{content.p}</p>
      <div className="skill-list">
        <div className="frontend skill-stack">
          <h2>Front-End</h2>
          {Object.entries(content.list.frontend).map((item) => (
            <SKillItem key={item[0]} name={item[0]} content={item[1]} />
          ))}
        </div>
        <div className="backend skill-stack">
          <h2>Back-End</h2>
          {Object.entries(content.list.backend).map((item) => (
            <SKillItem key={item[0]} name={item[0]} content={item[1]} />
          ))}
        </div>
        <div className="otherends skill-stack">
          <h2>Other-End</h2>
          {Object.entries(content.list.otherend).map((item) => (
            <SKillItem key={item[0]} name={item[0]} content={item[1]} />
          ))}
        </div>
      </div>
      <BottomLink text={content.link} linkTo="#resume" />
    </section>
  );
}

export default Skills;
