import "./style.css";
import "../../Assets/js.svg";
import useIcons from "../../Hooks/useIcons";

function SKillItem({ content, name }) {
  const icons = useIcons();

  return (
    <div className={`item ${name}`}>
      <div className="title">
        {!Array.isArray(content.img) ? (
          <img
            className={icons[content.img].name.toLowerCase()}
            src={icons[content.img].path}
            alt=""
          />
        ) : (
          <>
            {content.img.map((img) => (
              <img
                className={icons[img].name.toLowerCase()}
                src={icons[img].path}
                alt=""
              />
            ))}
          </>
        )}
        <h3>{content.title}</h3>
      </div>
      <div className="separator" />
      <p>{content.description}</p>
    </div>
  );
}

export default SKillItem;
