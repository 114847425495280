import "./language.css";

function Language({ content }) {
  return (
    <>
      <h1>{content.modal_h1}</h1>
      <div className="btn-row">
        <a
          href={content.url[0]}
          target="_blank"
          rel="noreferrer"
          className="pt language"
        >
          {content.modal_btn_pt}
        </a>
        <a
          href={content.url[1]}
          target="_blank"
          rel="noreferrer"
          className="en language"
        >
          {content.modal_btn_en}
        </a>
      </div>
    </>
  );
}

export default Language;
