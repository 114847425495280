import "./style.css";
import Dots from "./Dots";

function SoonProject({ position, content }) {
  return (
    <div className="project ">
      <div className={`video-container video-${position} soon_video`}>
        <Dots xl />
      </div>
      <div className={`info-container info-${position} soon_info`}>
        <div className="soon_description">
          <p>{content}</p>
        </div>
        <div className="aditional-info">
          <div className="soon_stack" />
          <div className="buttons">
            <div className="button">
              <div className="circle" />
              <div className="rectangle">
                <Dots sm />
              </div>
            </div>
            <div className="button">
              <div className="circle" />
              <div className="rectangle">
                <Dots sm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoonProject;
