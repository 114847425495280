import { createContext } from "react";
import useLanguage from "../Hooks/useLanguage";

const GlobalContext = createContext({});

function GlobalContextProvider(props) {
  const language = useLanguage();
  return (
    <GlobalContext.Provider value={language}>
      {props.children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalContextProvider };
