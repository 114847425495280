import css from "../Assets/css3.svg";
import express from "../Assets/expressjs.svg";
import git from "../Assets/git.svg";
import github from "../Assets/bi_github_20x20.svg";
import html5 from "../Assets/html5.svg";
import js from "../Assets/js.svg";
import nodejs from "../Assets/nodejs.svg";
import postgres from "../Assets/postgres.svg";
import react from "../Assets/react.svg";
import figma from "../Assets/figma.svg";
import photoshop from "../Assets/photoshop.svg";
import english_2 from "../Assets/english_2.svg";
import linkedin from "../Assets/llinkedin-white.png";
import email from "../Assets/email-white.png";
import whatsapp from "../Assets/whats-white.png";

function useIcons() {
  const icons = {
    html5: {
      name: "Html5",
      path: html5,
    },
    css: {
      name: "Css3",
      path: css,
    },
    js: {
      name: "JavaScript",
      path: js,
    },
    react: {
      name: "React",
      path: react,
    },
    nodejs: {
      name: "Node",
      path: nodejs,
    },
    express: {
      name: "Express",
      path: express,
    },
    postgres: {
      name: "Postgres",
      path: postgres,
    },
    git: {
      name: "Git",
      path: git,
    },
    github: {
      name: "GitHub",
      path: github,
    },
    figma: {
      name: "Figma",
      path: figma,
    },
    photoshop: {
      name: "Photoshop",
      path: photoshop,
    },
    english_2: {
      name: "English_2",
      path: english_2,
    },
    linkedin: {
      name: "LinkedIn",
      path: linkedin,
    },
    email: {
      name: "Email",
      path: email,
    },
    whatsapp: {
      name: "WhatsApp",
      path: whatsapp,
    },
  };

  return icons;
}

export default useIcons;
