import "./style.css";
import linkedIcon from "../../Assets/llinkedin-white.png";
import emailIcon from "../../Assets/email-white.png";
import whatsIcon from "../../Assets/whats-white.png";
import useGlobal from "../../Hooks/useGlobal";
import BottomLink from "../../Components/BottomLink";

function AboutMe() {
  const {
    language: { aboutme: content },
  } = useGlobal();

  return (
    <section className="about-me" id="about-me">
      <div className="left-side">
        <div className="color_layer" />
      </div>
      <div className="right-side">
        <div className="content">
          <p className="overtitle">{content.overTitle}</p>
          <h1>{content.h1}</h1>
          <p className="paragraph">{content.p.strA}</p>
          <p className="paragraph">
            {content.p.strB}{" "}
            <span className="yellow">{content.p.contrastB}</span>
          </p>
          <p className="paragraph">
            {content.p.strC1}{" "}
            <span className="yellow">{content.p.contrastC1}</span>{" "}
            {content.p.strC2}{" "}
            <span className="yellow">{content.p.contrastC2}.</span>
            <br />
            {content.p.strC3}
          </p>
          <p className="paragraph">{content.p.strC4}</p>
          <p className="paragraph">{content.p.strD}</p>
          <div className="contacts">
            <h2>{content.p.endTitle}</h2>
            <p className="paragraph">{content.p.strE}</p>
            <div className="contact-links">
              <span>
                <a
                  href="https://www.linkedin.com/in/marcio-pires/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedIcon} alt="" />
                  <p className="paragraph">LinkedIn</p>
                </a>
              </span>

              <span>
                <a
                  href="mailto:marciodgart@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={emailIcon} alt="" />
                  <p className="paragraph">E-mail</p>
                </a>
              </span>
              <span className="whats-btn">
                <a
                  href="https://api.whatsapp.com/send?phone=5571993044291&text=Ol%C3%A1!"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={whatsIcon} alt="" />
                  <p className="paragraph">WhatsApp</p>
                </a>
              </span>
            </div>
          </div>
        </div>
        <BottomLink text={content.link} linkTo="#skills" />
      </div>
    </section>
  );
}

export default AboutMe;
