import Header from "../../Pages/Header";
import TopBar from "../TopBar";
import AboutMe from "../../Pages/AboutMe";
import Resume from "../../Pages/Resume";
import Modal from "../../Components/Modal";
import Projects from "../../Pages/Projects";
import Skills from "../../Pages/Skills";
import Footer from "../../Pages/Footer";
import { useEffect } from "react";
import ipLocation from "../../Conection/ipLocation";
import { getStorage } from "../../Utils/Storage";
import useGlobal from "../../Hooks/useGlobal";
import { useState } from "react";

function MainWrapper() {
  const { storedLang: verified } = useGlobal();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    if (verified && verified) {
      console.log("return useEfect");
      return;
    }

    async function getUserLocation() {
      console.log("entrou em getUserLocation");
      try {
        console.log("entrou em getUserLocation TRY");
        const response = await ipLocation();
        if (response.data.country_code) {
          localStorage.setItem(
            "language",
            JSON.stringify({ lang: response.data.country_code, checked: true })
          );
          console.log("Completou TRY");
        }
      } catch (error) {
        console.log("entrou em getUserLocation CATCH");
        localStorage.setItem("language", JSON.stringify({ lang: "BR" }));
        console.log(error);
      }
    }
    getUserLocation();
  }, []);

  console.log(`MainWrapper: ${modalType}`);

  function xabs() {
    console.log("I'm xabs do...");
  }

  function crazy() {
    console.log("I'm crazy do...");
  }

  return (
    <>
      <TopBar />
      {openModal && <Modal openModal={setOpenModal} modalType={modalType} />}
      <Header />
      <AboutMe />
      <Skills />
      <Resume openModal={setOpenModal} setModalType={setModalType} />
      <Projects openModal={setOpenModal} setModalType={setModalType} />
      <Footer />
    </>
  );
}

export default MainWrapper;
