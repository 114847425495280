import axios from "axios";

const api = axios.create({
  baseURL: "http://api.ipapi.com/api",
  timeout: 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

async function getLocation() {
  try {
    const response = await api.get(
      `/check?access_key=${process.env.REACT_APP_ACCESS_KEY}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export default getLocation;
