function getStorage(storage) {
  const storageItem = localStorage.getItem(storage);
  return JSON.parse(storageItem);
}

function setStorage(name, item) {
  localStorage.setItem(name, JSON.stringify(item));
}

export { getStorage, setStorage };
