import "./style.css";
import useIcons from "../../Hooks/useIcons";

function Footer() {
  const { github, linkedin, email, whatsapp } = useIcons();

  console.log(linkedin);

  return (
    <footer>
      <p>
        Designed &amp; Coded from scratch by{" "}
        <span className="footer-name">MarcioPires</span>
      </p>
      <div className="footer-links">
        <a
          href="https://github.com/MarcioVPires"
          target="_blank"
          rel="noreferrer"
        >
          <img src={github.path} alt="" />
        </a>
        <a
          href="https://www.linkedin.com/in/marcio-pires/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin.path} alt="" />
        </a>
        <a href="mailto:marciodgart@gmail.com" target="_blank" rel="noreferrer">
          <img src={email.path} alt="" />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5571993044291&text=Ol%C3%A1!"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp.path} alt="" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
