import "./style.css";

function Dots({ xl, sm }) {
  const size = xl ? "dots_xl" : "dots_sm";

  return (
    <div className="dots-container">
      <span className={`dots ${size}`} />
      <span className={`dots ${size}`} />
      <span className={`dots ${size}`} />
    </div>
  );
}

export default Dots;
