import ptBR from "../Languages/pt-br.json";
import en from "../Languages/en.json";
import { useEffect, useState } from "react";
import { getStorage, setStorage } from "../Utils/Storage";

function useLanguage() {
  const [language, setLanguage] = useState(ptBR);
  const [storedLang, setStoredLang] = useState(getStorage("language"));

  useEffect(() => {
    if (!storedLang) {
      setStorage("language", { lang: "BR" });
      setStoredLang(getStorage("language"));
      return;
    }

    function getLang() {
      console.log("useLanguage");
      console.log(storedLang);

      if (storedLang.lang === "BR") {
        setLanguage(ptBR);
      }

      if (storedLang.lang !== "BR") {
        setLanguage(en);
      }
    }
    getLang();
  }, [storedLang]);

  function changeLang(lang) {
    setStorage("language", { ...storedLang, lang });
    setStoredLang(getStorage("language"));
  }

  return { language, changeLang, storedLang };
}

export default useLanguage;
