import "./style.css";
import useGlobal from "../../Hooks/useGlobal";
import Project from "../../Components/Project";
import BottomLink from "../../Components/BottomLink";

function Projects({ openModal, setModalType }) {
  const {
    language: { projects: content },
  } = useGlobal();

  return (
    <section className="projects-wrapper" id="projects">
      <h1>{content.h1}</h1>
      <p className="paragraph">{content.p}</p>

      {Object.entries(content.list).map((project, index) => (
        <Project
          content={project[1]}
          openModal={openModal}
          setModalType={setModalType}
          soon={project[0] === "soon" ? project[1] : false}
          position={index % 2 === 0 ? "left" : "right"}
        />
      ))}
    </section>
  );
}

export default Projects;
