import "./style.css";
import useGlobal from "../../Hooks/useGlobal";
import Close from "../../Assets/close.svg";
import Language from "./Language";
import Video from "./Video";
import { useEffect, useState } from "react";

function Modal({ openModal, modalType }) {
  console.log("Modal");
  const {
    language: { resume: content },
  } = useGlobal();

  const [activeModal, setActiveModal] = useState("");
  const [modalClass, setModalClass] = useState("");

  useEffect(() => {
    switch (modalType) {
      case "language":
        setModalClass("language_modal");
        setActiveModal(<Language content={content} />);
        break;
      case "video":
        setModalClass("video_modal");
        setActiveModal(<Video />);
        break;
      default:
        break;
    }
  }, [modalType]);

  function closeModal(e) {
    const e_class = e.target.classList;
    if (
      e_class.contains("modal-wrapper") ||
      e_class.contains("close_btn") ||
      e_class.contains("language")
    ) {
      openModal(false);
    }
  }
  return (
    <div className="modal-wrapper" onClick={closeModal}>
      <div className={`modal-container ${modalClass}`}>
        <img src={Close} className="close_btn" alt="" />
        {activeModal}
      </div>
    </div>
  );
}

export default Modal;
