import "./style.css";
import Github from "../../Assets/bi_github_20x20.svg";
import Github_Disabled from "../../Assets/bi_github_20x20_disabled.svg";
import project_demo from "../../Assets/payment-management-demo.mp4";
import Eye from "../../Assets/eye.svg";
import Eye_Disabled from "../../Assets/eye_disabled.svg";
import SoonProject from "../PlaceHolders/SoonProject";

function Project({ content, openModal, setModalType, soon, position }) {
  function openVideo(e) {
    setModalType("video");
    openModal(true);
  }

  return (
    <>
      {console.log(`position: ${position}`)}
      {!soon ? (
        <div className="project">
          <div className={`video-container video-${position}`}>
            <video
              autoPlay={"autoplay"}
              preLoad="auto"
              muted
              onClick={openVideo}
            >
              <source src={!soon ? project_demo : ""} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={`info-container info-${position}`}>
            <h2>{content.h2}</h2>
            <div className={`description`}>
              <p>{content.p}</p>
            </div>
            <div className={`aditional-info `}>
              <p className="stack">{content.stack}</p>

              <div className="buttons">
                <a
                  href={content.github ? content.github : "#/"}
                  className={!content.github ? "disabled" : ""}
                >
                  <img src={content.github ? Github : Github_Disabled} alt="" />
                  {content.btn_1}
                </a>
                <a
                  href={content.live ? content.live : "#/"}
                  className={!content.live ? "disabled" : ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={content.live ? Eye : Eye_Disabled} alt="" />
                  {content.btn_2}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SoonProject position={position} content={content} />
      )}
    </>
  );
}

export default Project;
